// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acesso-a-informacao-index-tsx": () => import("./../../../src/pages/acesso-a-informacao/index.tsx" /* webpackChunkName: "component---src-pages-acesso-a-informacao-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sections-acesso-a-informacao-index-tsx": () => import("./../../../src/pages/_sections/_acesso-a-informacao/index.tsx" /* webpackChunkName: "component---src-pages-sections-acesso-a-informacao-index-tsx" */),
  "component---src-pages-sections-conheca-nossos-fundos-index-tsx": () => import("./../../../src/pages/_sections/_conheca-nossos-fundos/index.tsx" /* webpackChunkName: "component---src-pages-sections-conheca-nossos-fundos-index-tsx" */),
  "component---src-pages-sections-consistencia-index-tsx": () => import("./../../../src/pages/_sections/_consistencia/index.tsx" /* webpackChunkName: "component---src-pages-sections-consistencia-index-tsx" */),
  "component---src-pages-sections-documentos-index-tsx": () => import("./../../../src/pages/_sections/_documentos/index.tsx" /* webpackChunkName: "component---src-pages-sections-documentos-index-tsx" */),
  "component---src-pages-sections-epecialistas-index-tsx": () => import("./../../../src/pages/_sections/_epecialistas/index.tsx" /* webpackChunkName: "component---src-pages-sections-epecialistas-index-tsx" */),
  "component---src-pages-sections-fundos-index-tsx": () => import("./../../../src/pages/_sections/_fundos/index.tsx" /* webpackChunkName: "component---src-pages-sections-fundos-index-tsx" */),
  "component---src-pages-sections-hero-index-tsx": () => import("./../../../src/pages/_sections/_hero/index.tsx" /* webpackChunkName: "component---src-pages-sections-hero-index-tsx" */),
  "component---src-pages-sections-newsletter-index-tsx": () => import("./../../../src/pages/_sections/_newsletter/index.tsx" /* webpackChunkName: "component---src-pages-sections-newsletter-index-tsx" */),
  "component---src-pages-sections-principios-index-tsx": () => import("./../../../src/pages/_sections/_principios/index.tsx" /* webpackChunkName: "component---src-pages-sections-principios-index-tsx" */),
  "component---src-pages-sections-principios-que-geram-resultados-index-tsx": () => import("./../../../src/pages/_sections/_principios-que-geram-resultados/index.tsx" /* webpackChunkName: "component---src-pages-sections-principios-que-geram-resultados-index-tsx" */),
  "component---src-pages-sections-processo-index-tsx": () => import("./../../../src/pages/_sections/_processo/index.tsx" /* webpackChunkName: "component---src-pages-sections-processo-index-tsx" */),
  "component---src-pages-sections-receba-nossa-newsletter-index-tsx": () => import("./../../../src/pages/_sections/_receba-nossa-newsletter/index.tsx" /* webpackChunkName: "component---src-pages-sections-receba-nossa-newsletter-index-tsx" */),
  "component---src-pages-sections-solucoes-index-tsx": () => import("./../../../src/pages/_sections/_solucoes/index.tsx" /* webpackChunkName: "component---src-pages-sections-solucoes-index-tsx" */),
  "component---src-pages-sections-um-time-de-especialistas-com-voce-index-tsx": () => import("./../../../src/pages/_sections/_um-time-de-especialistas-com-você/index.tsx" /* webpackChunkName: "component---src-pages-sections-um-time-de-especialistas-com-voce-index-tsx" */),
  "component---src-pages-sections-vantagens-index-tsx": () => import("./../../../src/pages/_sections/_vantagens/index.tsx" /* webpackChunkName: "component---src-pages-sections-vantagens-index-tsx" */),
  "component---src-pages-tipos-de-fundos-index-tsx": () => import("./../../../src/pages/tipos-de-fundos/index.tsx" /* webpackChunkName: "component---src-pages-tipos-de-fundos-index-tsx" */),
  "component---src-templates-fundos-listados-index-tsx": () => import("./../../../src/templates/fundosListados/index.tsx" /* webpackChunkName: "component---src-templates-fundos-listados-index-tsx" */),
  "component---src-templates-fundos-nao-listados-index-tsx": () => import("./../../../src/templates/fundosNaoListados/index.tsx" /* webpackChunkName: "component---src-templates-fundos-nao-listados-index-tsx" */)
}

